//breakpoints are same as bootstrap
$breakpoints: ("small": 36em,
  "medium": 48em,
  "large": 62em,
  "xtra-large": 75em);

@mixin breakpoint($size) {
  @media only screen and (max-width: map-get($map: $breakpoints, $key: $size)) {
    @content
  }
}

@mixin fontSizeAndFamily ($type, $size) {
  @if $type=='heading' {
    font-family: 'Open Sans', sans-serif;

    @if $size=='xl' {
      font-size: clamp(3rem, 2.5vw, 4rem);
    }

    @else if $size=='l' {
      font-size: clamp(2rem, 2vw, 3rem);
    }

    @else if $size=='m' {
      font-size: clamp(1.5rem, 1.5vw, 2.4rem);
    }

    @else if $size=='s' {
      font-size: clamp(1.5rem, 1.5vw, 2rem);
    }

    @else {
      font-size: clamp(1.2rem, 1.2vw, 1.6rem);
    }

  }

  @else {
    font-family: 'IBM Plex Mono', monospace;

    @if $size=='xl' {
      font-size: clamp(1.5rem, 1.5vw, 2.4rem);
    }

    @else if $size=='l' {
      font-size: clamp(1.5rem, 1.5vw, 2rem);
    }

    @else if $size=='m' {
      font-size: clamp(1.2rem, 1.2vw, 1.6rem);
    }

    @else if $size=='s' {
      font-size: clamp(1.2rem, 1vw, 1.5rem);
    }

    @else {
      font-size: clamp(1rem, 0.75vw, 1.2rem);
    }
  }
}