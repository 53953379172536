.map__container {
  height    : calc(100vh - #{$header-height});
  min-width : calc(100vw - #{$sidenav-width});
  place-self: flex-end;

  @include breakpoint('medium') {
    width: 100vw;
  }
}

#map {
  height: 100%;
  width : 100%;
}