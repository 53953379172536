.sidenav {
  height          : 100vh;
  width           : $sidenav-width;
  position        : absolute;
  top             : 0;
  left            : 0;
  background-color: $color-honeydew;
  z-index         : 1;

  @include breakpoint('medium') {
    z-index   : 9;
    transform : translateX(-32rem);
    transition: transform 0.5s ease-in-out;
  }

  &.open {
    transform: translateX(0);
  }
}

.close__sidenav__container {
  height         : $header-height;
  display        : flex;
  justify-content: flex-end;
  align-items    : center;

  .close__sidenav__button {
    height: 5rem;
    cursor: pointer;
  }
}

.places__list {
  overflow-y: auto;
  height    : calc(100vh - #{$header-height});
  position  : relative;

  &__placeholder {
    position  : absolute;
    top       : 40%;
    text-align: center;
    padding   : 1rem;
    @include fontSizeAndFamily('heading', 'm');
    color: $color-caledon-blue;
  }

  .venue {
    color          : $color-prussian-blue;
    height         : 7rem;
    display        : flex;
    align-items    : center;
    padding        : 1rem;
    cursor         : pointer;
    border-bottom  : 1px solid rgba(128, 128, 128, 0.192);
    display        : flex;
    flex-direction : column;
    justify-content: space-evenly;

    &:first-child {
      border-top: 1px solid rgba(128, 128, 128, 0.192);
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $color-caledon-blue;
      color           : $color-honeydew;
    }

    &[data-active="true"] {
      background-color: $color-prussian-blue;
      color           : $color-honeydew;
    }

    span {
      width        : 100%;
      overflow     : hidden;
      white-space  : nowrap;
      text-overflow: ellipsis;
    }

    &__name {
      @include fontSizeAndFamily('heading', 's');
    }

    &__address {
      @include fontSizeAndFamily('text', 's');
    }

    @include breakpoint("medium") {
      height: 6rem;

      &__name {
        @include fontSizeAndFamily('heading', 's');
      }

      &__address {
        @include fontSizeAndFamily('text', 's');
      }
    }
  }
}