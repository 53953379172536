.header {
  height          : $header-height;
  background-color: $color-prussian-blue;
  box-shadow      : 0 0 2px 2px rgba(0, 0, 0, 0.4);
  position        : relative;
  z-index         : 2;

  @include breakpoint("medium") {
    z-index: unset;
  }

  &__content {
    height     : 100%;
    display    : flex;
    align-items: center;
    padding    : 0.5rem;
  }
}

.sidenav__control {
  display: none;
  height : 5rem;
  cursor : pointer;

  @include breakpoint("medium") {
    display: block;
  }
}

.header__title {
  @include fontSizeAndFamily('heading', 'xl');
  color: $color-imperial-red;

  @include breakpoint("medium") {
    flex           : 1;
    display        : flex;
    justify-content: center;
  }
}

.form {
  display        : flex;
  gap            : 1.5rem;
  flex           : 1;
  justify-content: flex-end;

  &__input {
    display       : flex;
    flex-direction: column-reverse;

    label {
      @include fontSizeAndFamily('text', 's');
      color: $color-power-blue;
    }

    input {
      border   : none;
      outline  : none;
      padding  : 0.5rem;
      min-width: 16rem;
      @include fontSizeAndFamily('text', 'm');
      background   : transparent;
      border-bottom: 2px solid rgba($color-imperial-red, 0.3);
      color        : $color-honeydew;

      &:focus {
        border-bottom: 2px solid $color-imperial-red;
        background   : transparent;
      }

      &:autofill {
        box-shadow: none;
      }

      @include breakpoint("medium") {
        min-width: 20rem;
      }
    }

    input::placeholder {
      color: rgba($color-honeydew, 0.3);
    }
  }

  &__button {
    border          : none;
    outline         : none;
    width           : 10rem;
    height          : 4rem;
    align-self      : center;
    background-color: $color-caledon-blue;
    @include fontSizeAndFamily('heading', 's');
    padding        : 0.75rem;
    color          : $color-honeydew;
    border-radius  : 0.5rem;
    box-shadow     : 0 0 2px 0 rgba(0, 0, 0, 0.1);
    border         : 2px solid $color-caledon-blue;
    display        : flex;
    justify-content: center;
    align-items    : center;
    cursor         : pointer;

    &__loader {
      height : 4rem;
      width  : 4rem;
      display: none;
    }

    &:focus {
      border: 2px solid $color-power-blue;
    }

    &:disabled {
      cursor: progress;
    }

    &:disabled>&__text {
      display: none;
    }

    &:disabled>&__loader {
      display: block;
    }
  }

  @include breakpoint("medium") {
    display: none;
  }

  &.open {
    top             : 0;
    left            : 0;
    transform       : translate(calc(50vw - 50%), calc(50vh - 50%));
    position        : fixed;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    z-index         : 9;
    background-color: $color-prussian-blue;
    padding         : 2rem;
    border-radius   : 0.5rem;
  }
}

.search__control {
  height : 4rem;
  cursor : pointer;
  display: none;

  @include breakpoint("medium") {
    display: block;
  }
}