@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=Open+Sans&display=swap');

* {
  padding   : 0;
  margin    : 0;
  box-sizing: border-box;
}

html,
body {
  font-size  : 62.5%;
  font-family: 'IBM Plex Mono', monospace;
  width      : 100%;
  height     : 100%;
}

.container {
  width : min(86vw, 1440px);
  margin: auto;

  @include breakpoint('large') {
    width: 96vw;
  }
}

main {
  display: grid;
}

.overlay {
  position        : fixed;
  top             : 0;
  left            : 0;
  height          : 100vh;
  width           : 100vw;
  background-color: rgba($color: $color-prussian-blue, $alpha: 0.3);
  display         : none;

  &.open {
    display: block;
  }
}

.alert {
  position     : fixed;
  right        : 3rem;
  bottom       : 3rem;
  z-index      : 2;
  border-radius: 0.5rem;
  @include fontSizeAndFamily('text', 's');
  width           : 24rem;
  padding         : 1rem;
  background-color: $color-imperial-red;
  box-shadow      : 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  color           : $color-honeydew;
  transform       : translateY(200px);
  transition      : transform 0.5s ease-in-out;
  text-align      : center;

  &.open {
    transform: translateY(0);
  }
}